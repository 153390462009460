// About.js
import React from 'react';
import Layout from "../components/layout";

const JoinTogka = () => {
    return (
        <Layout>
            <h1>Join TOGKA</h1>
            <h3>Individuals looking for a dojo</h3>
            <p>Whether you are a begginer, or have been training most of you life in either Goju Ryu or another system,
                you are more than welcome to train with us, and join the TOGKA.</p>
            <p>We have dojos in several countries so please check the Members drop down menu for a dojo in your country.
                If we do not have a dojo near you please <a href="/Contact">contact us</a>, as we may be able to
                recommend a reputable dojo for you as an alternative.</p>
            <h3>Dojos wanting to join TOGKA</h3>
            <p>If you are a martial arts school or organisation looking to join the TOGKA we are more than happy to give
                you any information you require about the benefits of joining and how we can help you.
                You are welcome to contact a member in your country (view members by looking at the Members drop down
                menu), or please <a href="/Contact">contact us</a> with your enquiry and we will get back to you with
                more information.</p>
            <h3>New Country wanting to join TOGKA</h3>
            <p>If you are a martial arts school or organisation interested in joining the TOGKA we are more than happy
                to give you any information you require about the benefits of joining and how we can help you.
                Please check to see of we already have a member to contact in your country (view members by looking at
                the Members drop down menu), or <a href="/Contact">contact us</a> with your enquiry and we will get back
                to you with more information.</p>
        </Layout>);
}

export default JoinTogka;
